/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { LinkButton } from "gatsby-interface"

import Container from "../../components/container"
import Layout from "../../layout"
import SiteHead from "../../head"
import Title from "../../components/hero/title"
import LegendTable from "../../components/features/legend-table"
import EvaluationTable from "../../components/features/evaluation-table"
import CompareButton from "../../components/features/compare-button"
import useComparisonState from "../../components/hooks/use-comparison-state"
import featureComparisonOptions from "../../../data/feature-comparison-options.json"
import { getFeaturesData } from "../../utils/get-csv-features-data"
import PageWithSidebar from "../../components/page-with-sidebar"
import Breadcrumb from "../../components/docs/breadcrumb"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

import { DocsSidebarNav } from "../../components/sidebar/docs-sidebar-nav"

export function Head(props) {
  return <SiteHead {...props} title="Gatsby vs JAMstack frameworks" />
}

const JamstackFeaturesPage = ({
  data: { allGatsbyJamstackSpecsCsv },
  location,
}) => {
  const [selected, setSelected, comparators, hasSelected] = useComparisonState({
    nextjs: false,
    jekyll: false,
    hugo: false,
    nuxtjs: false,
  })

  const { sections, sectionHeaders } = getFeaturesData(
    allGatsbyJamstackSpecsCsv.nodes
  )

  return (
    <Layout pathname={location.pathname}>
      <PageWithSidebar location={location} SidebarComponent={DocsSidebarNav}>
        <main
          sx={t => ({
            pb: 0,
            [t.mediaQueries.desktop]: {
              pt: 10,
            },
          })}
        >
          <SkipNavTarget />
          <Container css={{ position: `static` }}>
            <Breadcrumb location={location} />
            <Title>JAMstack</Title>
            <p>Compare popular JAMstack technologies on this page.</p>
            <LegendTable />

            <h3>Comparison</h3>
            <p>
              To see a filtered view of Gatsby with specific JAMstack
              technologies, choose the technologies to compare and then press
              Compare:
            </p>

            <div
              sx={{
                display: `grid`,
                gridTemplateColumns: `repeat(auto-fit, minmax(75px, 1fr))`,
                gridAutoRows: `1fr`,
                gridGap: 3,
                pb: 5,
              }}
            >
              {featureComparisonOptions.jamstack.map(
                ({ key: optionKey, display }) => (
                  <CompareButton
                    key={optionKey}
                    optionKey={optionKey}
                    selected={selected[optionKey]}
                    setSelected={setSelected}
                  >
                    {display}
                  </CompareButton>
                )
              )}
            </div>
            <LinkButton
              to={
                hasSelected
                  ? `/features/jamstack/gatsby-vs-${comparators.join(`-vs-`)}`
                  : location.pathname
              }
              size="M"
            >
              Compare with Gatsby
            </LinkButton>
            <EvaluationTable
              options={featureComparisonOptions.jamstack}
              sections={sections}
              sectionHeaders={sectionHeaders}
            />
          </Container>
        </main>
      </PageWithSidebar>
    </Layout>
  )
}

export default JamstackFeaturesPage

export const pageQuery = graphql`
  query JamstackPageQuery {
    allGatsbyJamstackSpecsCsv {
      nodes {
        Category
        Subcategory
        Feature
        Gatsby
        Nextjs
        Jekyll
        Hugo
        Nuxtjs
        Description
      }
    }
  }
`
